import { lazy } from 'react';

const routes = [
  {
    path: '',
    component: lazy(() => import('./../../Pages/HomePage')),
    exact: true
  },
  {
    path: 'activity/:id/:title',
    component: lazy(() => import('./../../Pages/ListCommentPage')),
    exact: true
  },
  {
    path: 'activity-add',
    component: lazy(() => import('./../../Pages/CreateActivityPage')),
    exact: true
  },
  {
    path: 'setting',
    component: lazy(() => import('./../../Pages/SettingPage')),
    exact: true
  },

  {
    path: 'setting/change-password',
    component: lazy(() => import('./../../Pages/ChangePasswordPage')),
    exact: true
  },
  {
    path: 'setting/update-profile',
    component: lazy(() => import('./../../Pages/SettingPage')),
    exact: true
  },
];

export default routes;