import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes'; // Route list
import Loader from '../../Components/Mikro/Loader';

const ProtectedRoutes = (props) => (
  <Switch>
    <Suspense
      fallback={<Loader />}
    >
      {routes.map(({ component: Component, path, exact }) => (
        <Route
          path={`${props.match.path}/${path}`}
          key={path}
          exact={exact}
          component={Component} 
        />
      ))}
    </Suspense>
  </Switch>
);

export default ProtectedRoutes;