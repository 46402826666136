import React, {Component} from 'react';
import Helmet from "react-helmet";

import './../Styles/bootstrap.min.css';
import './../Styles/bootstrap-icons.css';
import './../Styles/style.css';

import {
  BrowserRouter as Router,
  Switch ,
  Route,
  Redirect
} from 'react-router-dom';

import ProtectedRoutes from '../Config/Routes/ProtectedRoutes'; //Authenticated routes
import PrivateRoute from '../Config/Routes/PrivateRoute'; 

import { getToken, setDataUser } from '../Utils/HelperUtil';

import LoginPage from './Auth/LoginPage'; 

class App extends Component {
  state = {
    darkMode: false,
    redirect: '',
  }

  componentDidMount() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : '309964374267980',
        cookie     : true,                     // Enable cookies to allow the server to access the session.
        xfbml      : true,                     // Parse social plugins on this webpage.
        version    : 'v12.0'           // Use this Graph API version for this call.
      });

      //TUJUAN NYA UNTUK MENGECEK APAKAH SUDAH LOGIN ATAU BELUM.
      // JIKA SUDAH LANGSUNG BISA REDIRECT DARI FUNGSI this.statusChangeCallback(response);
      window.FB.getLoginStatus(function(response) {
        this.statusChangeCallback(response);
      }.bind(this));
    }.bind(this);

    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }
  
// Here we run a very simple test of the Graph API after login is
// successful.  See statusChangeCallback() for when this call is made.
  testAPI() {
    let self = this
    window.FB.api('/me', 'get', { fields: 'id,name,email,picture.width(200).height(200)' },function(response) {

      if(response.id){

        let profile = {
          id:response.id,
          name:response.name,
          email:response.email,
          picture: response.picture.data.url || '',
        }
        setDataUser(profile)
        self.setState({ redirect:'/app' })
      }else{
        window.FB.logout(function(response) {
          alert('Now you are logout')
        });
      }
    // document.getElementById('status').innerHTML =
    //   'Thanks for logging in, ' + response.name + '!';
    });
  }

  // This is called with the results from from window.FB.getLoginStatus().
  statusChangeCallback(response) {
    // console.log('statusChangeCallback');
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for window.FB.getLoginStatus().
    if (response.status === 'connected') {
      // Logged into your app and Facebook.
      localStorage.setItem('auth', JSON.stringify(response.authResponse));
      this.testAPI();
    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not your app.
      console.log('Please log into this app.')
    } else {
      // they are logged into this app or not.
      console.log('Please log into Facebook.')
    }
  }
  
  render(){
    let auth = getToken()
    const isAuthenticated = auth !== null  ? true : false
    let {darkMode, redirect} = this.state
    return (
      <div>
        <Helmet htmlAttributes={{ 'data-theme': darkMode ? "dark" : "light"}}/>
        
        {redirect !== '' ? <Redirect to={redirect} /> :''}
        <Router>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            {/* <Route path="/daftar" component={Register} />
            <Route path="/informasi-verifikasi" component={InformasiVerifikasi} />
            <Route path="/lupa-password" component={LupaPassword} />
            <Route path="/informasi-cek-email" component={InformasiCekEmail} />
            <Route path="/informasi-selesai-registrasi" component={InformasiSelesaiRegistrasi} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/verifikasi/:token" component={VerifikasiEmail} /> */}
        
            <PrivateRoute path="/app" isAuthenticated={isAuthenticated} component={ProtectedRoutes} />
            <Route render={() => { return <h1>404 Not Found</h1> }} />
          </Switch>

        </Router>
      </div>
    )
  }
}
export default App;