//user
export const getDataUser = () => { 
    return JSON.parse(localStorage.getItem('user'));
}
export const setDataUser = (data) => {
    localStorage.setItem('user', JSON.stringify(data))
    return true
}
export const emtpyDataUser = () => {
    localStorage.removeItem("user");
    return true
}
export const getToken = () => {
    let auth = localStorage.getItem('auth')
    if(auth && auth.accessToken != ''){
        return auth.accessToken
    } else return null
}

export const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const urlParam = (arr) => {
    let x = '?'

    Object.keys(arr).map(function(item,i,y) {

        x = x + item + '=' + arr[item] +(i != (y.length -1)  ? '&':'')
    });
    return x
}

export const zeroPad = (x, y)=>{
    y = Math.max(y-1,0);
    var n = (x / Math.pow(10,y)).toFixed(y);
    return n.replace('.','');  
}