import react, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import LoginImage from '../../Assets/Image/36.png'
import { setDataUser } from './../../Utils/HelperUtil'

export default class LoginPage extends Component {
  state = {
    redirect: '',
  }

  componentDidMount() {
  }

  testAPI() {
    let self = this
    window.FB.api('/me', 'get', { fields: 'id,name,email,picture.width(200).height(200)' },function(response) {
      if(response.id){
        let profile = {
          id:response.id,
          name:response.name,
          email:response.email,
          picture: response.picture.data.url || '',
        }
        setDataUser(profile)
        self.handleRedirect()
      }else{
        window.FB.logout(function(response) {
          alert('Now you are logout')
        });
      }
    });
  }

  // This is called with the results from from window.FB.getLoginStatus().
  statusChangeCallback(response) {
    // for window.FB.getLoginStatus().
    if (response.status === 'connected') {
      // Logged into your app and Facebook.
      localStorage.setItem('auth', JSON.stringify(response.authResponse));
      this.testAPI();
    } else if (response.status === 'not_authorized') {
      // console.log('Please log into this app.')
    } else {
      // console.log('Please log into Facebook.')
    }
  }

  checkLoginState() {
    window.FB.getLoginStatus(function(response) {
      this.statusChangeCallback(response);
    }.bind(this));
  }

  handleClick() {
    window.FB.login(this.checkLoginState());
  }

  handleRedirect(){
   let self = this
     setTimeout(()=>{
        self.setState({ redirect:'/app' })
      },500)
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return <div>
      <div className="internet-connection-status" id="internetStatus"></div>
      
      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          <div className="text-center px-4"><img className="login-intro-img" src={LoginImage} alt=""/></div>
        
          <div className="register-form mt-4 text-center">
            <h6 className="mb-3 text-center">Login untuk masuk di GetFix.</h6>
              {/* <div className="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div> */}
              <button 
                className="btn m-1 w-100 btn-creative btn-primary"
                onClick={() => this.handleClick()}
              >
                <i className="bi bi-facebook me-2"></i>
              Login with Facebook</button>
          </div>
          <div className="login-meta-data text-center">
            {/* <a className="stretched-link forgot-password d-block mt-3 mb-3" href="page-forget-password.html">Lupa Password?</a> */}
            {/* <p className="mb-0">Didn't have an account?<br/> <a className="btn btn-outline-info w-100" href="page-register.html">Register Now</a></p> */}
          </div>
        </div>
      </div>
    </div>
  }
}